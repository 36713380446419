/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/@appkit4/styles/appkit.min.css";
@import "../node_modules/@appkit4/styles/themes/appkit.orange.min.css";




.k-dialog-titlebar {
  background-color: #d04a02 !important;
}

.q-tree-container .one {
  display: none !important;
}

.actionButtonSecondary {
  background-color: #6b6b6b;
}

.paragraphText {
  font-family: "PwC Helvetica Neue", sans-serif;
  font-size: 16px !important;
  margin-top: -0.1px;
}

.btn-pwc-primary {
  cursor: pointer;
  padding: 0.3vw 2vw;
  font-weight: bold;
  background-color: #dc6900;
  color: #eeeeee;
  border: none;
}

.btn-pwc-primary {

  &:hover,
  &:focus,
  &:active,
  &.active {
    cursor: pointer;
    padding: 0.3vw 2vw;
    font-weight: bold;
    background-color: #dc6900;
    color: #eeeeee;
    border: none;
  }
}

.btn-pwc-secondary {
  cursor: pointer;
  padding: 0.3vw 2vw;
  font-weight: bold;
  background-color: #6b6b6b;
  color: #eeeeee;
  border: none;
}

.btn-pwc-secondary {

  &:hover,
  &:focus,
  &:active,
  &.active {
    cursor: pointer;
    padding: 0.3vw 2vw;
    font-weight: bold;
    background-color: #6b6b6b;
    color: #eeeeee;
    border: none;
  }
}

.btn-pwc-primary:disabled,
.btn-pwc-sec:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.k-radio,
.k-checkbox {
  border-color: grey;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: #d04a02;
  background-color: #d04a02;
}

/*styles for header menu*/
.k-menu:not(.k-context-menu)>.k-item {
  font-family: "PwC Helvetica Neue";
  color: #474747;
  font-size: 16px;
}

.mat-menu-content:not(:empty) {
  position: absolute;
  top: 58px;
  right: 0px;
  padding: 8px 12px;
  border: 1px solid #fff;
  border-radius: var(--border-radius-3);
  transition: all 0.3s;
  box-shadow: var(--box-shadow-3) !important;
  background-color: #fff;
  line-height: 1.5rem;
}

button.mat-menu-item {
  background-color: white;
}

button.mat-menu-item:hover {
  background-color: #f3f3f3;
}

.submenu:hover {
  background-color: #d04a02 !important;
}

/*ends*/
::ng-deep {
  .ap-field-email-validation-error {
    // margin: $spacing-3 0 $spacing-4 $spacing-3;
    margin-top: 5px;
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    color: red;
  }
}

.k-window-content {
  background-color: white;
}

.fontcolorlabel {
  background-color: #dedede;
  color: #000000;
  text-decoration-color: #7d7d7d;
}

.ap-field-input {
  line-height: 2rem !important;
  height: 2rem !important;
}

.header {
  font-size: 20px;
  line-height: 1rem;
  background-color: #d04a02;
  padding: 14px 24px;
  color: #ffffff;
  margin: 10px 0px;
}

.searchContainer {
  display: flex;
  padding: 0px 0px;
  padding: 10px 0px;
}

.subHeader {
  font-size: 18px;
  line-height: 0.5rem;
  padding: 14px 24px;
  background-color: #dedede;
  margin-bottom: 10px;
}

.clickToActions {
  display: flex;
  flex-flow: row-reverse nowrap;
}

.gold {
  // font-size: larger !important;
  font-weight: bolder;
}

.k-group-indicator,
.k-grid .k-grouping-row td,
.k-grouping-header-flex {
  display: none !important;
}

.k-grouping-header-flex .k-group-indicator {
  display: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #d04a02 !important;
  color: white;
  background-color: #d04a02 !important;
}

.k-grid td.k-state-selected,
.k-grid td.k-selected,
.k-grid tr.k-state-selected>td,
.k-grid tr.k-selected>td {
  background-color: #fedacc;
}

div.k-dialog-content {
  padding: 20px !important;
}

div.k-window-title {
  font-size: 20px !important;
}

.cancelButton,
.okButton>button {
  width: 100px;
}

//for habitat popup
// .k-window.k-dialog {
//   width: 500px !important;
//   height: auto !important;
// }
.cancelButton,
.okButton>button {
  width: 100px;
}

.k-tabstrip-items-wrapper .k-item {
  color: #d04a02;
}

// kendo-label > .k-label {
//   margin-top: 3px !important;
// }
.k-grid th {
  color: #d04a02 !important;
}

.highlighted {
  background-color: #ffecbd !important;
}

.exportXL {
  background: #fff;
  border: 1px solid #d04a02;
  color: #d04a02;
  letter-spacing: -0.025rem;
  justify-content: center;
  padding: 8px 24px;
  font-weight: 600;
}

.k-group-cell,
.k-group-col {
  display: none;
}

.k-i-menu {
  color: grey;
}

// .menu-right .k-menu-expand-arrow {
//   color:grey
// }
.k-form .k-label,
.k-form kendo-label,
.k-form .k-form-label {
  align-items: center;
}

.border-piechart .k-chart-surface {
  height: 82%;
}